<template>
  <PageWithLayout>
    <search
      :tabData="viewModel.tabData"
      :searchDataList.sync="searchData.searchDataList"
      :searchParams.sync="searchData.searchParams"
      @onUpdateTab="viewModel.onUpdateTab()"
      @onSearch="viewModel.onSearch()"
    />
    <Board
      :boardData="viewModel.boardData"
      :searchParams.sync="searchData.searchParams"
      :dataList="searchData.dataList"
      :paginationData="searchData.paginationData"
      @onClickRow="(rowId, rowData) => viewModel.onClickRow(rowId, rowData)"
      @onSearch="viewModel.onSearch()"
    >
      <template v-slot:headRight>
        <Button
          btnSize="small"
          btnStyle="primary"
          text="등록하기"
          @onClickBtn="viewModel.onClickRegister()"
        />
      </template>
    </Board>
    <template v-slot:popup>
      <AdvertisementRegisterPopup
        v-if="viewModel.isAdvertisementRegisterPopup"
        :isModify="viewModel.isModify"
        :isVaild="viewModel.validatedResult()"
        :model.sync="viewModel.model"
        @onClickClose="viewModel.onClickCloseAdvertisementRegisterPopup()"
        @onClickComplete="viewModel.onClickCompleteAdvertisementRegisterPopup()"
      />
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import Search from '@lemontree-ai/lemontree-admin-common-front/components/common/search/Search';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
// popup
import AdvertisementRegisterPopup from '@/views/app/advertisement/view/popup/AdvertisementRegisterPopup';
// viewModel
import AdvertisementListViewModel from '@/views/app/advertisement/viewModel/AdvertisementListViewModel';

export default {
  name: 'AdvertisementList',
  components: {
    PageWithLayout,
    Search,
    Board,
    Button,
    AdvertisementRegisterPopup,
  },
  data() {
    return {
      viewModel: new AdvertisementListViewModel(),
    };
  },
  computed: {
    searchData() {
      return this.viewModel.searchData[this.viewModel.tabData.value];
    },
  },
  beforeMount() {
    this.viewModel.init(this.$route.query);
  },
};
</script>
