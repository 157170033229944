import { GET_REQUEST, POST_REQUEST, PUT_REQUEST } from '@/services/http';
import apiPath from '@/services/apiPath';
import router from '@/router';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import { makeQueryStringByObject } from '@lemontree-ai/lemontree-admin-common-front/utils/urlUtils';
import { convertDateFormat, checkPastDate } from '@lemontree-ai/lemontree-admin-common-front/utils/dateUtils';
import AdvertisementModel from '@/views/app/advertisement/model/AdvertisementModel';
import { pageValidated } from '@lemontree-ai/lemontree-admin-common-front/utils/validateUtils';

export default class AdvertisementListViewModel {
  constructor() {
    this.tabData = {
      dataList: [
        { id: 'ALL', text: '전체' },
        { id: 'target', text: '타겟으로 검색' },
        { id: 'inventory', text: '광고위치로 검색' },
        { id: 'status', text: '상태로 검색' },
      ],
      option: {
        isCount: false,
      },
      value: 'ALL',
    };
    this.searchData = {
      ALL: {
        searched: false,
        searchedValue: '',
        hasData: true,
        searchDataList: [],
        searchParams: {
          page: 0,
          pageSize: 10,
          direction: 'DESC',
        },
        paginationData: {
          totalCount: 0,
          totalPage: 0,
        },
        dataList: [],
        responseDate: '',
      },
      target: {
        searched: false,
        searchedValue: '',
        hasData: true,
        searchDataList: [
          {
            title: '타겟구분',
            type: 'Radio',
            dataList: 'user_target',
            isAddDataListAll: false,
            isFull: true,
            id: 'target',
            value: null,
          },
        ],
        searchParams: {
          page: 0,
          pageSize: 10,
          direction: 'DESC',
        },
        paginationData: {
          totalCount: 0,
          totalPage: 0,
        },
        dataList: [],
        responseDate: '',
      },
      inventory: {
        searched: false,
        searchedValue: '',
        hasData: true,
        searchDataList: [
          {
            title: '광고 위치',
            type: 'Radio',
            dataList: 'advertisement_inventory',
            isAddDataListAll: false,
            isFull: true,
            id: 'inventory',
            value: null,
          },
        ],
        searchParams: {
          page: 0,
          pageSize: 10,
          direction: 'DESC',
        },
        paginationData: {
          totalCount: 0,
          totalPage: 0,
        },
        dataList: [],
        responseDate: '',
      },
      status: {
        searched: false,
        searchedValue: '',
        hasData: true,
        searchDataList: [
          {
            title: '상태',
            type: 'Radio',
            dataList: 'advertisement_status',
            isAddDataListAll: false,
            isFull: true,
            id: 'status',
            value: null,
          },
        ],
        searchParams: {
          page: 0,
          pageSize: 10,
          direction: 'DESC',
        },
        paginationData: {
          totalCount: 0,
          totalPage: 0,
        },
        dataList: [],
        responseDate: '',
      },
    };
    this.boardData = {
      title: '광고관리목록',
      drawDataList: [
        {
          title: 'No.',
          width: '50px',
          value: 'id',
        },
        {
          title: '광고섬네일',
          width: '120px',
          value: 'image',
          img: {
            size: 'contain',
            width: '105px',
            height: '55px',
            position: {
              x: 'center',
              y: 'center',
            },
          },
        },
        {
          title: '제목/설명',
          width: '',
          value: 'title',
          valueCustom: [
            {
              type: 'text',
              value: '<strong>',
            },
            {
              type: 'value',
              value: 'title',
            },
            {
              type: 'text',
              value: '</strong><br>',
            },
            {
              type: 'value',
              value: 'description',
            },
          ],
          isAlignLeft: true,
          isEllip: true,
        },
        {
          title: '코드',
          width: '',
          value: 'code',
          // class: {
          //   name: 'td_point',
          // },
          isAlignLeft: true,
        },
        {
          title: '우선<br>순위',
          width: '50px',
          value: 'priority',
        },
        {
          title: '광고<br>마크',
          width: '50px',
          value: 'isAdvertisement',
          filter: {
            name: 'convertIdToText',
            value: 'common_boolean_ox',
          },
          class: {
            classColorFilter: {
              name: 'convertIdToColor',
              dataListName: 'common_boolean',
              prev: 'tc',
            },
          },
        },
        {
          title: '타겟구분',
          width: '62px',
          value: 'target',
          filter: {
            name: 'convertIdToText',
            value: 'user_target',
          },
          class: {
            classColorFilter: {
              name: 'convertIdToColor',
              dataListName: 'user_target',
              prev: 'tc',
            },
          },
        },
        {
          title: '광고<br>위치',
          width: '62px',
          value: 'inventory',
          filter: {
            name: 'convertIdToText',
            value: 'advertisement_inventory',
          },
          // class: {
          //   classColorFilter: {
          //     name: 'convertIdToColor',
          //     dataListName: 'advertisement_inventory',
          //     prev: 'fw_bold tc',
          //   },
          // },
        },
        // {
        //   title: '생성자',
        //   width: '60px',
        //   value: 'createdBy',
        //   isAlignLeft: true,
        // },
        // {
        //   title: '생성일시',
        //   width: '100px',
        //   value: 'createdAt',
        //   filter: {
        //     name: 'convertDateFormat',
        //     value: 'YYYY-MM-DD HH:mm',
        //   },
        //   isAlignLeft: true,
        // },
        {
          title: '게시기간',
          width: '110px',
          value: '',
          dateRangeValue:{
            startValue: 'startAt',
            endValue: 'endAt',
            betweenText: ' ~ ',
            isEndPastDateToRed: true
          },
          isAlignLeft: true,
        },
        {
          title: '상태',
          width: '80px',
          value: 'status',
          filter: {
            name: 'convertIdToText',
            value: 'advertisement_status',
          },
          badge: {
            badgeColorFilter: {
              name: 'convertIdToColor',
              dataListName: 'advertisement_status',
            },
            badgeFill: false,
          },
        },
      ],
      option: {
        isTotal: true,
        isSize: true,
      },
    };
    this.dataList = [];
    this.searchParams = {
      page: 0,
      pageSize: 10,
      direction: 'DESC',
    };
    this.paginationData = {
      totalCount: 0,
      totalPage: 0,
    };

    this.model = new AdvertisementModel();
    this.isAdvertisementRegisterPopup = false;
    this.isModify = false;

    this.valid = {
      imageList: {
        value: 'imageList',
        valueType: 'array',
        isValid: false,
        validAlertDesc: '섬네일을 첨부해주세요',
      },
      code: {
        value: 'code',
        isValid: false,
        validAlertDesc: '코드를 입력해주세요',
      },
      priority: {
        value: 'priority',
        isValid: false,
        validAlertDesc: '우선순위를 입력해주세요',
      },
      link: {
        value: 'link',
        isValid: false,
        validAlertDesc: '링크 URL을 입력해주세요',
      },
      startAt: {
        value: 'startAt',
        isValid: false,
        validAlertDesc: '게시기간을 입력해주세요',
      },
      endAt: {
        value: 'endAt',
        isValid: false,
        validAlertDesc: '게시기간을 입력해주세요',
      },
    };
  }

  init(query) {
    const { pageSize, page } = query;
    if (pageSize) {
      this.searchParams.pageSize = Number(pageSize);
    }
    if (page) {
      this.searchParams.page = Number(page);
    }
    this.getList();
  }

  validatedResult() {
    return pageValidated(this.valid, this.model);
  }

  // 상세
  onClickRow(rowId, rowData) {
    this.model.setData(rowData);

    this.isModify = true;
    this.isAdvertisementRegisterPopup = true;
  }
  onClickCloseAdvertisementRegisterPopup() {
    this.isAdvertisementRegisterPopup = false;
    this.model = new AdvertisementModel();
  }
  onClickCompleteAdvertisementRegisterPopup() {
    if (this.isModify) {
      this.putAdvertisement();
    } else {
      this.postAdvertisement();
    }
  }

  onClickRegister() {
    this.isModify = false;
    this.isAdvertisementRegisterPopup = true;
  }
  onUpdateTab() {
    this.setTabData();
  }
  setTabData() {
    let markId = '';
    const searchDataList = this.searchData[this.tabData.value].searchDataList;
    const isSearchDataList = searchDataList && searchDataList.length > 0;
    let value = isSearchDataList ? searchDataList[0].value : '';
    switch (this.tabData.value) {
      case 'ALL': {
        this.valueText = '전체';
        this.endWord = '를';
        markId = '';
        value = '';
        break;
      }
      case 'target': {
        this.valueText = '타겟';
        this.endWord = '을';
        markId = 'user_id';
        value = Number(value);
        break;
      }
      case 'MEMBERSHIP_ID': {
        this.valueText = '광고 위치';
        this.endWord = '를';
        markId = '';
        value = '';
        break;
      }
      case 'PURCHASE_METHOD_TYPE': {
        this.valueText = '상태';
        this.endWord = '를';
        markId = '';
        value = '';
        break;
      }
    }
    const tabText = this.tabData.dataList.find(
      item => item.id === this.tabData.value,
    ).text;
    let allBoardTitle = '전체 구매내역';
    this.boardData.title = isSearchDataList ? `${tabText}결과` : allBoardTitle;
  }

  onSearch() {
    this.getList();
  }
  // 목록 데이터
  getList() {
    // this.searchData.searchDataList.forEach(item =>{
    //   if(item.value){
    //     this.searchParams[item.id] = item.value;
    //   }else{
    //     delete this.searchParams[item.id];
    //   }
    // })
    this.searchData[this.tabData.value].searched = false;
    // const searchDataList = this.searchData[this.tabData.value].searchDataList;
    // const isSearchDataList = searchDataList && searchDataList.length > 0;
    let searchPath = '';
    const searchData = this.searchData[this.tabData.value];
    const serachDataListByTab = searchData.searchDataList;
    if(serachDataListByTab.length > 0){
      let value = this.searchData[this.tabData.value].searchDataList[0].value;
      if(this.tabData.value != 'ALL' && value){
        searchPath = `/${this.tabData.value}/${value}`;
      }
    }
    const query = makeQueryStringByObject(searchData.searchParams);
    let path = `${apiPath.ADVERTISEMENT_LIST}${searchPath}${query}`;
    GET_REQUEST(path).then(
      success => {
        this.searchData[this.tabData.value].searched = true;
        const resultData = success.data;
        this.searchData[this.tabData.value].dataList = resultData.list.map(item => {
          item.disabled = Boolean(checkPastDate( item.endAt, 'yesIsPast' )) || item.status === 'UNREGISTERED'
          return item
        });
        this.searchData[this.tabData.value].paginationData.totalCount = resultData.total;
        this.searchData[this.tabData.value].paginationData.totalPage = resultData.totalPage;
        this.searchData[this.tabData.value].responseDate = convertDateFormat(new Date(), 'YYYY-MM-DD HH:mm:ss');
        this.searchData[this.tabData.value].hasData = resultData.total > 0;
      },
      failed => {
        store.dispatch('commonToast/fetchToastStart', failed.msg);
      },
    );
  }

  postAdvertisement() {
    const path = `${apiPath.ADVERTISEMENT_REGISTER}`;
    const data = this.model.getData();
    POST_REQUEST(path, data).then(
      success => {
        const resultData = success.data;
        store.dispatch('commonToast/fetchToastStart', '광고가 등록되었습니다');
        this.isAdvertisementRegisterPopup = false;
        this.onSearch();
      },
      failed => {
        store.dispatch('commonToast/fetchToastStart', failed.msg);
      },
    );
  }

  putAdvertisement() {
    const path = `${apiPath.ADVERTISEMENT_MODIFY.format(this.model.id)}`;
    const data = this.model.getData();
    PUT_REQUEST(path, data).then(
      success => {
        const resultData = success.data;
        store.dispatch('commonToast/fetchToastStart', '광고가 수정되었습니다');
        this.isAdvertisementRegisterPopup = false;
        this.onSearch();
      },
      failed => {
        store.dispatch('commonToast/fetchToastStart', failed.msg);
      },
    );
  }
}
